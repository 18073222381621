<template>
  <div class="create-product">
    <v-container fluid>
      <div class="welcome">
        <v-btn @click.prevent.native="backToAll" icon color="blue">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <h1>Add Bag</h1>
      </div>

      <v-row>
        <v-col cols="12" :md="8">
          <v-form ref="form" @submit.prevent="attemptSave">
            <base-card>
              <v-card-title>Bag Details</v-card-title>
              <v-card-text>
                <v-text-field
                  label="Bag Description"
                  :disabled="isSaving"
                  v-model="product.name"
                  :rules="nameRules"
                  required
                />

                <v-text-field
                  label="Stock Purchase Number"
                  :disabled="isSaving"
                  v-model="product.stock_number"
                />

                <v-text-field
                  label="Sales Invoice Number"
                  :disabled="isSaving"
                  v-model="product.sales_number"
                />


                <!-- <v-textarea
                  label="Description"
                  :disabled="isSaving"
                  v-model="product.description"
                  :rules="descriptionRules"
                  required
                /> -->

                <v-select
                  disabled
                  label="Status"
                  v-model="product.status"
                  :items="statusOptions"
                  class="form-control"
                >
                </v-select>
              </v-card-text>
            </base-card>

            <base-card class="mt-6">
              <v-card-title>Pricing</v-card-title>
              <v-card-text>
                <v-select
                  label="Currency"
                  :disabled="isSaving"
                  v-model="product.currency"
                  :items="currencyDropdownOptions"
                  class="form-control"
                >
                </v-select>

                <v-text-field
                  :prefix="currencyCodeToSymbol(product.currency)"
                  label="Purchase Price"
                  :disabled="isSaving"
                  v-model="product.purchase_price"
                  :rules="amountRules"
                  required
                />

                <v-text-field
                  :prefix="currencyCodeToSymbol(product.currency)"
                  label="Expected Sale Price"
                  :disabled="isSaving"
                  v-model="product.expected_sale_price"
                  :rules="amountRules"
                  required
                />
              </v-card-text>
            </base-card>

            <base-card class="mt-6">
              <v-card-title>Additional Properties</v-card-title>
              <v-card-text>
                <v-select
                  label="Style"
                  :disabled="isSaving"
                  v-model="product.bag_style_id"
                  :items="bagStyleDropdownOptions"
                  class="form-control"
                >
                </v-select>

                <v-select
                  label="Condition"
                  :disabled="isSaving"
                  v-model="product.bag_condition_id"
                  :items="bagConditionDropdownOptions"
                  class="form-control"
                >
                </v-select>

                <v-select
                  label="Colour"
                  :disabled="isSaving"
                  v-model="product.bag_colour_id"
                  :items="bagColourDropdownOptions"
                  class="form-control"
                >
                </v-select>

                <v-select
                  label="Hardware"
                  :disabled="isSaving"
                  v-model="product.hardware_id"
                  :items="hardwareDropdownOptions"
                  class="form-control"
                >
                </v-select>

                <v-select
                  label="Skin"
                  :disabled="isSaving"
                  v-model="product.bag_skin_id"
                  :items="bagSkinDropdownOptions"
                  class="form-control"
                >
                </v-select>

                <v-select
                  label="Size"
                  :disabled="isSaving"
                  v-model="product.bag_size_id"
                  :items="bagSizeDropdownOptions"
                  class="form-control"
                >
                </v-select>
              </v-card-text>
            </base-card>
          </v-form>


        </v-col>
        <v-col :cols="12" :md="4">
          <base-card>
            <v-card-title>Bag Images</v-card-title>
            <uploader
              v-model="fileList"
              title="Please upload bag images"
              :autoUpload="false"
            ></uploader>
          </base-card>
        </v-col>
      </v-row>
      <v-btn
            :loading="isSaving"
            :disabled="isSaving"
            @click.prevent.native="attemptSave"
            color="primary"
            class="mt-6"
            >Save</v-btn
          >
    </v-container>
  </div>
</template>

<script>
import Forms from "../../../../mixins/Forms";
import products from "../../../../api/products";
import Uploader from "vux-uploader-component";
import axios from "axios";
import Context from "../../../../mixins/Context";
import Products from "../../../../mixins/Products";
import Display from "../../../../mixins/Display";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isSaving: false,
      product: {
        name: "",
        description: "",
        status: 0,

        currency: "GBP",
        purchasePrice: null,
        expectedPurchasePrice: null,

        bagStyleId: null,
        bagConditionId: null,
        bagColourId: null,
        hardwareId: null,
        bagSkinId: null,
        bagSizeId: null,
      },

      nameRules: [(v) => !!v || "Name is required"],
      descriptionRules: [(v) => !!v || "Description is required"],
      amountRules: [
        (v) => !!v || "Amount is required",
        (v) => /^\d+(\.\d+)?$/.test(v) || "Must be a number",
        // (v) =>
        //   (v && v.toString().split(".")[0].length <= 5) ||
        //   "No more than 5 digits before the decimal point",
        (v) =>
          (v && v.toString().split(".").length < 2) ||
          (v && v.toString().split(".")[1].length <= 2) ||
          "No more than 2 digits after the decimal point",
      ],

      statusOptions: [
        {
          value: 0,
          text: "Available for consignor to purchase",
        },
        {
          value: 1,
          text: "Assigned stock to consignor",
        },
        {
          value: 2,
          text: "Paid for by consignor",
        },
        {
          value: 3,
          text: "Sold to end client",
        },
      ],
      fileList: [],
    };
  },

  components: { Uploader },

  computed: {
    ...mapGetters("context", {
      context: "context",
    }),
  },

  mixins: [Forms, Context, Products, Display],

  methods: {
    backToAll() {
      this.$router.push("/products");
    },

    attemptSave() {
      if (this.fileList.length == 0) {
        this.$toast.error("Please upload a product image");
        return false;
      }

      let isValid = this.$refs["form"].validate();
      if (isValid) {
        this.save();
      }
    },

    save() {
      let vm = this;
      vm.isSaving = true;

      let params = {
        ...this.product,
        purchase_price: Number(this.product.purchase_price) * 100,
        expected_sale_price: Number(this.product.expected_sale_price) * 100,
      };

      products
        .create(params)
        .then((r) => {
          // vm.isSaving = false;
          vm.$toast.success("Bag created successfully");

          let reqs = [];
          _.each(this.fileList, (file) => {
            reqs.push(products.addProductImage(r.data.id, file.blob));
          });
          axios
            .all(reqs)
            .then((r) => {
              vm.$router.push("/products/" + r.data.id);
            })
            .catch((e) => {
              console.log(e);
              vm.$router.push("/products/" + r.data.id);
            });
        })
        .catch((e) => {
          console.log(e);
          let errors = vm.getErrorsAsArray(e);
          let error;
          if (errors.length) {
            error = _.first(errors);
          } else {
            error = "Something went wrong. Please try again.";
          }
          vm.$toast.error(error);
          vm.isSaving = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
  padding-top: 20px;
}

.create-product {
  padding: 20px;

  .welcome {
    margin-bottom: 20px;
    display: flex;
    justify-items: center;

    h1 {
      margin-left: 10px;
    }
  }

  .product-property {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>
