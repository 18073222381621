const _ = require('lodash');

const STATUSES = [
    // {
    //     value: 0,
    //     label: 'Not Purchased'
    // },
    // {
    //     value: 1,
    //     label: 'Purchased'
    // },
    // {
    //     value: 2,
    //     label: 'In Stock'
    // },
    // {
    //     value: 3,
    //     label: 'Sold'
    // }
    {
        value: 0,
        // label: 'Not Purchased'
        label: 'Available for consignor to purchase'
    },
    {
        value: 1,
        label: 'Assigned stock to consignor'
    },
    {
        value: 2,
        label: 'Paid for by consignor'
    },
    {
        value: 3,
        label: 'Sold to end client'
    }
];

const CURRENCIES = ['GBP', 'USD', 'EUR'];

export default {
    methods: {
        getProductPrimaryImage(product) {
            if (product && product.product_images && product.product_images.length) {
                let img = _.first(product.product_images);
                return img ? window.IMAGE_STORAGE_BASE + '/' + img.image : null;
            }
            return null;
        },

        getProductImageSrc(productImage) {
            return productImage ? window.IMAGE_STORAGE_BASE + '/' + productImage.image : null;
        },

        getHumanReadableStatus(status) {
            let option = _.find(STATUSES, { value: status });
            return option ? option.label : 'Unknown';
        }
    },

    computed: {
        statusDropdownOptions() {
            return _.map(STATUSES, status => {
                return {
                    value: status.value,
                    text: status.label
                }
            });
        },

        currencyDropdownOptions() {
            return _.map(CURRENCIES, currency => {
                return {
                    value: currency,
                    text: currency
                }
            });
        }
    }
}